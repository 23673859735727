import { InternalSubView, InternalRootView, component } from '../utils/route.utils'

import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'export'
export const ChannelLabel = '导出'

export const ExportRoute: RouteRecordRaw = {
  name: ChannelName,
  path: `/${ChannelName}`,
  meta: {
    label: '导出管理',
    icon: 'OfficeBuilding',
    group: { name: ChannelName, label: ChannelLabel },
    showInLeftMenu: true,
    href: (d: any) => {
      return `/${ChannelName}`
    },
    svg: ICON_SVG.bank
  },
  children: [
    {
      name: 'export-search',
      path: `/${ChannelName}`,
      component: component(ChannelName, `ExportTable`),
      meta: {
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        },
        svg: ICON_SVG.bank
      }
    }
  ]
}
