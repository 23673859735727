import { RouteRecordRaw } from 'vue-router'
import { ICON_SVG } from '../icon.svg'
import { useRoute } from '@/store/route.state'
import { InternalSubView, InternalRootView, component } from '@/routes/utils/route.utils'
export const ChannelName = 'access'
export const ChannelLabel = '权限'

export const AccessRoute: RouteRecordRaw = {
  name: ChannelName,
  path: `/ ${ChannelName} `,
  component: InternalRootView,
  meta: {
    label: `${ChannelLabel}管理`,
    svg: ICON_SVG.bank,
    icon: 'Tools',
    group: { name: ChannelName, label: ChannelLabel },
    showInLeftMenu: true,
    disabled: false,
    href: (d: any) => {
      return `/ ${ChannelName} `
    }
  },
  children: [
    {
      name: `${ChannelName} -search`,
      path: `/${ChannelName}`,
      component: component(ChannelName, 'AccessTree'),
      meta: {
        label: '权限管理',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        href: (d: any) => {
          return `/ ${ChannelName} `
        },
        svg: ICON_SVG.bank
      }
    },
    {
      name: `${ChannelName} -group`,
      path: `/${ChannelName}/group`,
      component: InternalSubView,
      meta: {
        label: '分组管理',
        icon: 'DocumentAdd',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        href: (d: any) => {
          return `/${ChannelName}/group`
        },
        svg: ICON_SVG.product
      },
      children: [
        {
          name: `${ChannelName}-group-search`,
          path: `/${ChannelName}/group`,
          component: component(ChannelName, 'AccessGroupTable'),
          meta: {
            label: '分组管理',
            icon: 'DocumentAdd',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: true,
            href: (d: any) => {
              return `/${ChannelName}/group`
            },
            svg: ICON_SVG.product
          }
        },
        {
          name: `${ChannelName}-group-edit`,
          path: `/${ChannelName}/group/:groupId(\\d+)`,
          component: component(ChannelName, 'AccessGroupEdit'),
          meta: {
            label: '编辑分组',
            icon: 'DocumentAdd',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: true,
            href: (d: any) => {
              const route = useRoute()
              return `/${ChannelName}/group/${route.accessGroupId}`
            },
            svg: ICON_SVG.product
          }
        }
      ]
    },
    {
      name: `${ChannelName}-role`,
      path: `/${ChannelName}/role`,
      component: InternalSubView,
      meta: {
        label: '角色管理',
        icon: 'DocumentAdd',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        href: (d: any) => {
          return `/${ChannelName}/role`
        },
        svg: ICON_SVG.product
      },
      children: [
        {
          name: `${ChannelName}-role-search`,
          path: `/${ChannelName}/role`,
          component: component(ChannelName, 'AccessRoleTable'),
          meta: {
            label: '角色管理',
            icon: 'DocumentAdd',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: true,
            href: (d: any) => {
              return `/${ChannelName}/role`
            },
            svg: ICON_SVG.product
          }
        },
        {
          name: `${ChannelName}-role-edit`,
          path: `/${ChannelName}/role/:roleId(\\d+)`,
          component: component(ChannelName, 'AccessRoleEdit'),
          meta: {
            label: '编辑角色',
            icon: 'DocumentAdd',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: true,
            href: (d: any) => {
              const route = useRoute()
              return `/${ChannelName}/role/${route.accessGroupId}`
            },
            svg: ICON_SVG.product
          }
        }
      ]
    }
  ]
}
